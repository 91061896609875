import b from 'b_';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import autosize from 'autosize';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import { FTLabel, FTInput, FTCaption } from '../Fuse';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import './FuseTextArea.css';
import './FuseTextArea.new.css';

let block = b.lock('FuseTextArea');
const newBlock = b.lock('Fuse5TextArea');

class FuseTextArea extends Component {
    static propTypes = {
        store: PropTypes.shape({
            getValue: PropTypes.func.isRequired,
            onChangeHandler: PropTypes.func,
            reset: PropTypes.func,
            setRef: PropTypes.func,
        }),
        className: PropTypes.string,
        label: PropTypes.node,
        placeholder: PropTypes.node,
        bottomText: PropTypes.node,
        clearIcon: PropTypes.string,
        rightIconClassName: PropTypes.string,
        error: PropTypes.bool,
        focus: PropTypes.bool,
        hover: PropTypes.bool,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        resize: PropTypes.string,
        scroll: PropTypes.bool,
        onKeyDown: PropTypes.func,
        rows: PropTypes.number,
    };

    static defaultProps = {
        resize: 'vertical',
        scroll: false,
        onKeyDown: () => null,
        rows: 6,
    };

    constructor(props) {
        super(props);
        block = isFuse5Enabled() ? newBlock : block;
        this.state = { focus: false };
        this.minWidth = 0;
    }

    componentDidMount() {
        const { resize, scroll } = this.props;
        if (this.inputEl && !scroll) {
            autosize(this.inputEl);
            this.minWidth = resize !== 'vertical' ? this.inputEl.clientWidth : 0;
        }
    }

    componentDidUpdate() {
        if (this.inputEl && !this.props.scroll) {
            autosize.update(this.inputEl);
        }
    }

    onRef = (el) => {
        this.inputEl = el;
        this.props.store?.setRef?.(el);
    };

    render() {
        const {
            store,
            className,
            label,
            placeholder,
            bottomText,
            clearIcon,
            rightIconClassName,
            onKeyDown,
            error,
            focus,
            hover,
            disabled,
            readOnly,
            resize,
            scroll,
            rows,
            ...otherProps
        } = this.props;

        return <label
            className={cn(block({
                error,
                focus: focus === undefined ? this.state.focus : focus,
                hover,
                disabled,
                readOnly,
            }), className)}
        >
            {label ? <FTLabel className={block('Label')}>{label}</FTLabel> : null}
            <div className={block('InputContainer')}>
                <div className={block('InputWrapper')}>
                    {placeholder && !store.getValue() && !this.state.focus ? <FTInput
                        className={block('Placeholder')}
                    >{placeholder}</FTInput> : null}
                    <textarea
                        className={block('Input', { resize, scroll })}
                        style={{ minWidth: this.minWidth }}
                        disabled={disabled || readOnly}
                        onFocus={() => this.setState({ focus: true })}
                        onBlur={() => this.setState({ focus: false })}
                        value={store.getValue()}
                        onChange={store.onChangeHandler}
                        onKeyDown={onKeyDown}
                        ref={this.onRef}
                        rows={rows}
                        {...otherProps}
                    />
                </div>
                {clearIcon ? <Icon
                    className={cn(block('Icon'), rightIconClassName)}
                    onClick={store.reset}
                    type="cancel"
                /> : null}
            </div>
            {bottomText ? <FTCaption
                className={block('BottomText')}
            >{bottomText}</FTCaption> : null}
        </label>;
    }
}

export default observer(FuseTextArea);
