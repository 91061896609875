import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import cn from 'classnames';
import b from 'b_';
import UDSpinner from '../UD/Fragments/UDSpinner';
import { FTHeading } from './Text';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import './Card.css';
import './Card.new.css';

let block = b.lock('Card');
const newblock = b.lock('Fuse5Card');

export default function Card({ className, title, titleAction, loading, noPadding, children, ...props }) {
    block = isFuse5Enabled() ? newblock : block;
    return <div className={cn(block({ noPadding }), className)} {...props}>
        {title || titleAction ? <div className={block('Header')}>
            {title ? <FTHeading className={block('Title')}>{title}</FTHeading> : null}
            {titleAction ? <Fragment>
                <div className={block('Spacer')} />
                <div className={block('TitleAction')}>{titleAction}</div>
            </Fragment> : null}
        </div> : null}
        {loading ? <UDSpinner
            className={block('Loader')}
            type="medium"
        /> : children}
    </div>;
}

Card.propTypes = {
    title: PropTypes.node,
    titleAction: PropTypes.node,
    loading: PropTypes.bool,
    noPadding: PropTypes.bool,
};
