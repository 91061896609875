import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import b from 'b_';
import CustomerContent from '../CustomerContent';
import FuseWysiwygReadOnly from '../Fuse/FuseWysiwyg/FuseWysiwygReadOnly';
import Icon from '../Icon/Icon';
import Bg from '../Bg/Bg';
import { PfptLogo, ProofpointUrlIsolationLogo } from '../Logo/PfptLogo';
import { TBody } from '../Text/Text';
import { getStyle, THEME_VALUES } from '../../themesStylesConfig';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import './BlockPage.css';

const block = b.lock('BlockPage');

class ContentFilteringPage extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        isUrlIsolation: PropTypes.bool,
        logo: PropTypes.string,
        headline: PropTypes.string,
        headlineIcon: PropTypes.bool,
        background: PropTypes.bool,
        message: PropTypes.string,
        content: PropTypes.array,
    };

    static defaultProps = {
        headlineIcon: true,
        background: true,
    };

    renderNew() {
        return <Bg
            className={block()}
            type={'none'}
        >
            <TBody className={block('UserContent')}>
                <FuseWysiwygReadOnly content={this.props.content} />
            </TBody>
        </Bg>;
    }

    renderOld() {
        const { t, isUrlIsolation, logo, headline, headlineIcon, message } = this.props;
        const defaultLogo = isUrlIsolation
            ? <ProofpointUrlIsolationLogo noShadow className={block('DefaultLogo')} />
            : <PfptLogo className={block('DefaultLogo')} />;
        return <Bg style={isFuse5Enabled() ? getStyle(THEME_VALUES.LIGHT) : {}}
            className={block()}
            type={'none'}
        >
            {logo ? <img alt="logo" src={logo} className={block('Logo')} /> : defaultLogo}
            {headline === '' ? null : <div className={block('Title')}>
                {headlineIcon ? <Icon type="report" className={block('TitleIcon')} /> : null}
                {headline || t('browserErrorPage.blockedWebsite.title')}
            </div>}
            <TBody className={block('Text')}>
                {message ? <CustomerContent html={message} /> : <div
                    style={{ alignText: 'center' }}
                >{t('browserErrorPage.blockedWebsite.template')}</div>}
            </TBody>
        </Bg>;
    }

    render() {
        return this.props.content ? this.renderNew() : this.renderOld();
    }
}

export default translate()(ContentFilteringPage);
