import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import b from 'b_';
import FuseWysiwygReadOnly from '../Fuse/FuseWysiwyg/FuseWysiwygReadOnly';
import { AuthBox, AuthPage } from './AuthLayout';
import PasswordlessAuthStore from '../../stores/PasswordlessAuthStore';
import FlipNext from '../Animation/FlipNext';
import { FTBody, FTHeadline, FTInput } from '../Fuse/Text';
import FuseTextfield from '../FuseControls/FuseTextfield';
import FuseButton from '../FuseControls/FuseButton';
import FuseAlert from '../FuseControls/FuseAlert';
import CustomerContent from '../CustomerContent';
import Actions from '../Actions/Actions';
import { isFuse5Enabled } from '../../fuseFeatureFlag';
import { getStyle, THEME_VALUES } from '../../themesStylesConfig';
import './BrowserLogin.css';

const block = b.lock('BrowserLogin');

class BrowserLogin extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        passwordlessAuth: PasswordlessAuthStore.PropType,
    };

    get isEmailSubmitted() {
        return this.props.passwordlessAuth.submitEmailState.successCount > 0;
    }

    componentDidUpdate() {
        const { error } = this.props.passwordlessAuth.submitEmailState;
        if (error) {
            window.location = '/auth/browser/error';
        }
    }

    onSubmit = (event) => {
        event.preventDefault();

        const { submitEmail, submitCode } = this.props.passwordlessAuth;

        if (this.isEmailSubmitted) {
            submitCode();
        } else {
            submitEmail();
        }
    };

    getEmailErrorLabel() {
        const { submitEmailState, email, expiredCode, expiredLink } = this.props.passwordlessAuth;
        if (expiredCode.isTrue) {
            return this.props.t('PasswordlessAuth.failure.codeExpired');
        }
        if (expiredLink.isTrue) {
            return this.props.t('PasswordlessAuth.failure.linkExpired');
        }
        if (!email.isTouched && !email.isValid) {
            return this.props.t('PasswordlessAuth.failure.invalidEmail');
        }
        if (!email.isTouched && submitEmailState.isFailed && submitEmailState.error) {
            return this.props.t('mirrorLogin.loginError1');
        }
        return '';
    }

    getCodeErrorLabel() {
        const { submitCodeState, code } = this.props.passwordlessAuth;
        if (submitCodeState.isFailed && !code.isTouched) {
            return this.props.t('PasswordlessAuth.failure.invalidCode');
        }
        return '';
    }

    renderAlert(emailPage) {
        const bodyText = emailPage ? this.getEmailErrorLabel() : this.getCodeErrorLabel();

        if (!bodyText) {
            return null;
        }

        return <FuseAlert
            className={block('MarginBottom', { section: 'alert' })}
            type="danger"
            icon="error"
            centerIcon
        >
            <FTInput className={block('AlertText')}>{bodyText}</FTInput>
        </FuseAlert>;
    }

    renderEmailPage() {
        const {
            submitEmailState,
            email,
            expiredCode,
            expiredLink,
            flipNextStore,
            isSSOManuallyLoadingState
        } = this.props.passwordlessAuth;

        return <AuthBox
            onSubmit={this.onSubmit}
            className={block('Display', { hide: flipNextStore.isTrue })}
        >
            <FTHeadline style={{ marginBottom: 10 }}>
                {this.props.t('PasswordlessAuth.welcomeTitle')}
            </FTHeadline>

            <FTBody className={block('MarginBottom', { section: 'textBody' })}>
                {this.props.t('PasswordlessAuth.enterEmailMessage')}
            </FTBody>

            {this.renderAlert(true)}

            <FuseTextfield
                store={email}
                autoFocus={!this.isEmailSubmitted}
                placeholder={this.props.t('PasswordlessAuth.emailPlaceholder')}
                className={block('MarginBottom', { section: 'textfield' })}
                error={!!this.getEmailErrorLabel() && !expiredCode.isTrue && !expiredLink.isTrue}
            />

            <FuseButton
                submit
                type={'primary'}
                color={'blue'}
                fullWidth
                disabled={email.isEmpty}
                loading={submitEmailState.isLoading || isSSOManuallyLoadingState.isTrue}
                onClick={this.onSubmit}
            >{this.props.t('PasswordlessAuth.next')}</FuseButton>
        </AuthBox>;
    }

    renderCodePage() {
        const { submitEmailState, submitCodeState, startAgain, email, code } = this.props.passwordlessAuth;
        const hide = !(this.isEmailSubmitted || submitEmailState.isLoading);

        return <AuthBox
            onSubmit={this.onSubmit}
            className={block('Display', { hide })}
        >
            <FTHeadline style={{ marginBottom: 10 }}>
                {this.props.t('PasswordlessAuth.success.title')}
            </FTHeadline>

            <FTBody className={block('MarginBottom', { section: 'textBody' })}>
                {this.props.t('PasswordlessAuth.success.subtitleCode')}
                <span className={block('Email')}>{email.value}</span>
            </FTBody>

            <FTBody className={block('MarginBottom', { section: 'textBody' })}>
                {this.props.t('PasswordlessAuth.codeFootnote')}
            </FTBody>

            {this.renderAlert()}

            <input
                className={block('Code', { error: !!this.getCodeErrorLabel() })}
                type="text"
                value={code.value}
                placeholder={this.props.t('PasswordlessAuth.codePlaceholder')}
                onChange={code.onChangeHandler}
                ref={code.setRef}
                maxLength={10}
            />

            <FuseButton
                type={'primary'}
                color={'blue'}
                fullWidth
                loading={submitCodeState.isLoading}
                disabled={!code.value}
                className={block('MarginBottom')}
                onClick={this.onSubmit}
            >
                {this.props.t('PasswordlessAuth.submitCode')}
            </FuseButton>

            <FTBody>
                {this.props.t('PasswordlessAuth.success.resendText')}
                <a className={block('ResendLink')} onClick={startAgain}>
                    {this.props.t('PasswordlessAuth.success.resendCode')}
                </a>
            </FTBody>
        </AuthBox>;
    }

    renderLinkPage() {
        const { submitEmailState, startAgain, email } = this.props.passwordlessAuth;
        const hide = !(this.isEmailSubmitted || submitEmailState.isLoading);

        return <AuthBox
            onSubmit={this.onSubmit}
            className={block('Display', { hide })}
        >
            <FTHeadline style={{ marginBottom: 10 }}>
                {this.props.t('PasswordlessAuth.success.title')}
            </FTHeadline>

            <FTBody className={block('MarginBottom', { section: 'textBody' })}>
                {this.props.t('PasswordlessAuth.success.subtitleLink')}
                <span className={block('Email')}>{email.value}</span>
            </FTBody>

            <FTBody className={block('MarginBottom', { section: 'textBody' })}>
                {this.props.t('PasswordlessAuth.linkFootnote')}
            </FTBody>

            {this.renderAlert()}

            <FTBody className={block('MarginBottom')}>{this.props.t('PasswordlessAuth.success.resendText')}</FTBody>

            <FuseButton
                type={'primary'}
                color={'blue'}
                fullWidth
                onClick={startAgain}
            >
                {this.props.t('PasswordlessAuth.resendButton')}
            </FuseButton>
        </AuthBox>;
    }

    renderRegistrationNoticePage() {
        const {
            submitEmailState,
            startAgain,
            acceptPrivacyPolicy,
            isSSOManuallyLoadingState
        } = this.props.passwordlessAuth;
        const { headline, message, content } = submitEmailState.result.content;
        const hide = !(this.isEmailSubmitted || submitEmailState.isLoading || isSSOManuallyLoadingState.isTrue);

        return <AuthBox
            onSubmit={this.onSubmit}
            className={block('Display', { hide })}
        >
            {content ? <div style={{ textAlign: 'left', width: '100%', padding: '0 10px' }}>
                <FuseWysiwygReadOnly content={content} />
            </div> : <>
                <FTHeadline style={{ marginBottom: 10 }}>
                    {headline}
                </FTHeadline>
                <FTBody className={block('MarginBottom', { section: 'textBody', htmlContent: true })}>
                    <CustomerContent html={message} />
                </FTBody>
            </>}

            <Actions className={block('Actions')}>
                <FuseButton
                    type="secondary"
                    color="blue"
                    fullWidth
                    disabled={submitEmailState.isLoading || isSSOManuallyLoadingState.isTrue}
                    onClick={startAgain}
                >
                    {this.props.t('PasswordlessAuth.success.cancel')}
                </FuseButton>
                <FuseButton
                    type="primary"
                    fullWidth
                    color="blue"
                    loading={submitEmailState.isLoading || isSSOManuallyLoadingState.isTrue}
                    onClick={acceptPrivacyPolicy}
                >
                    {this.props.t('PasswordlessAuth.success.accept')}
                </FuseButton>
            </Actions>
        </AuthBox>;
    }

    getBackPage() {
        const { passwordlessAuth } = this.props;
        if (passwordlessAuth.getRegistrationType() === 'code') {
            return this.renderCodePage();
        }
        if (passwordlessAuth.getRegistrationType() === 'link') {
            return this.renderLinkPage();
        }
        if (passwordlessAuth.getRegistrationType() === 'registrationNotice') {
            return this.renderRegistrationNoticePage();
        }
        if (passwordlessAuth.getRegistrationType() === 'sso') {
            return this.renderRegistrationNoticePage();
        }
        return <span />;
    }

    getPageContentClass() {
        if (this.props.passwordlessAuth.getRegistrationType() === 'registrationNotice'
            || (this.props.passwordlessAuth.noticeContent !== null
                && this.props.passwordlessAuth.getRegistrationType() === 'sso')) {
            return block('WideModal');
        }
    }

    render() {
        const { passwordlessAuth } = this.props;
        const flipNext = passwordlessAuth.flipNextStore.isTrue;
        return <AuthPage style={isFuse5Enabled() ? getStyle(THEME_VALUES.LIGHT) : {}}
            pageContentClass={this.getPageContentClass()}
        >
            <FlipNext flip={flipNext}>
                {flipNext
                    ? this.getBackPage()
                    : this.renderEmailPage()}
            </FlipNext>
        </AuthPage>;
    }
}

export default translate()(inject('passwordlessAuth')(observer(BrowserLogin)));
